import { defineStore } from 'pinia'
import _map from 'lodash/map.js'
import Bugsnag from '@bugsnag/js'

export default defineStore('strapi', {
    state: function () {
        return {
            jobs: [],
            team: [],
            pages: [],
            reports: [],
            faq: [],
            blogcategories: [],
            blogentries: [],
            // agb
            // impressum
        }
    },
    actions: {
        async fetchTeam() {
            const config = useRuntimeConfig()
            const baseURL = config.public.sources.strapi.url

            const { data } = await useFetch('/api/teams', {
                baseURL,
                params: { populate: '*', sort: 'order:ASC' },
            })

            const teamData = (data.value || data).data

            const _getOptimizedImage = (photoObject) => {
                // Structure should look like: phote: { ..., formats: { large: { url: ... }, medium: { url: ... }, small: { url: ... } }}
                // Use the small format for the team photos
                // Otherwise fall back to medium, large or the original
                if (photoObject) {
                    if ('formats' in photoObject) {
                        if ('small' in photoObject.formats) {
                            return photoObject.formats.small
                        }
                        if ('medium' in photoObject.formats) {
                            return photoObject.formats.medium
                        }
                        if ('large' in photoObject.formats) {
                            return photoObject.formats.large
                        }
                    }

                    return photoObject
                }

                return undefined
            } // eo _getOptimizedImage()

            this.team = _map(teamData, (member) => {
                const item = member.attributes

                return {
                    order: item.order,
                    name: item.name,
                    email: item.email,
                    position: item.position,
                    photo:
                        item.photo && item.photo.data
                            ? _getOptimizedImage(item.photo.data.attributes)
                            : null,
                    intro: item.intro,
                }
            })

            return this.team
        },

        async fetchPages() {
            const config = useRuntimeConfig()
            const baseURL = config.public.sources.strapi.url

            const { data } = await useFetch('/api/pages', {
                baseURL,
                params: { populate: '*' },
            })

            const pagesData = (data.value || data).data

            this.pages = _map(pagesData, (page) => {
                const item = page.attributes

                return {
                    id: page.id,
                    slug: item.slug,
                    title: item.title,
                    content: item.content,
                    updatedAt: item.updatedAt,
                    pdfDownload: item?.pdfDownload?.data?.attributes?.url
                        ? config.public.sources.strapi.url +
                          item?.pdfDownload?.data?.attributes?.url
                        : '',
                }
            })

            return this.pages
        },

        async fetchJobs() {
            const config = useRuntimeConfig()
            const baseURL = config.public.sources.strapi.url

            const { data } = await useFetch('/api/jobs', {
                baseURL,
                params: { sort: 'order:ASC' },
            })

            const jobsData = (data.value || data).data

            this.jobs = _map(jobsData, (job) => {
                const item = job.attributes

                return {
                    id: job.id,
                    title: item.title,
                    description: item.description,
                    order: item.order,
                    url: item.url,
                }
            })

            return this.jobs
        },

        fetchReports() {
            // const config = useRuntimeConfig()
            // const baseUrl = config.public.sources.strapi.url

            this.reports = [
                // {
                //     outlet: 'Pro Sieben GALILEO',
                //     headline: `Der Kleiderberg in der Wüste - Was ist hier passiert?`,
                //     excerpt: `Das Journalistenteam reist nach Chile, um den Kleiderberg in der Atacama-Wüste zu dokumentieren. Ab Minute 9:13 kommt das Team auch bei uns in Hamburg vorbei.`,
                //     date: new Date(2022, 2, 10),
                //     url: 'https://www.prosieben.de/tv/galileo/videos/202264-der-kleiderberg-in-der-wueste-was-ist-hier-passiert-clip?fbclid=IwAR010i0AyffMqlijgqcmZSpN9jLr1Su2ttG5a5os8M1bCDo4yB1hIsmx17Y',
                //     image: {
                //         width: 350,
                //         height: 350,
                //         url: '/uploads/team_Viko_442ac804ce.jpg',
                //         alternativeText: '',
                //     },
                // },
                {
                    outlet: 'RTL Nord',
                    headline: `Bericht über Altkleider`,
                    excerpt: ``,
                    date: new Date('2021-12-14'),
                    youtubeId: 'L7AGjFOzaak',
                    // image: {
                    //     width: 350,
                    //     height: 350,
                    //     url: '/uploads/team_Viko_442ac804ce.jpg',
                    //     alternativeText: '',
                    // },
                },
                {
                    outlet: 'NDR Nordreportage',
                    headline: `Das Geschäft mit der Umwelt`,
                    excerpt: ``,
                    date: new Date('2021-09-13'),
                    youtubeId: '_RtyhvgQZeM',
                    // image: {
                    //     width: 350,
                    //     height: 350,
                    //     url: '/uploads/team_Viko_442ac804ce.jpg',
                    //     alternativeText: '',
                    // },
                },
            ]

            /* return $fetch(
                baseUrl + '/api/news-articles?populate=*&sort=order:ASC',
            )
                .then((response) => response.data)
                .then((jobs) => {
                    this.jobs = _map(jobs, (job) => {
                        const item = job.attributes
                        return {
                            title: item.title,
                            email: item.email,
                            image: item.img ? item.img.data.attributes : null,
                            text: item.text,
                            order: item.order,
                        }
                    })
                }) */

            return this.reports
        },

        async fetchFAQ() {
            const config = useRuntimeConfig()
            const baseURL = config.public.sources.strapi.url

            const { data } = await useFetch('/api/faq-categories', {
                baseURL,
                params: { populate: '*', sort: 'order:ASC' },
            })

            const faqData = (data.value || data).data

            this.faq = _map(faqData, (category) => {
                const questions = _map(
                    category.attributes.questions.data,
                    (question) => {
                        const item = question.attributes

                        return {
                            id: question.id,
                            slug: item.slug,
                            question: item.question,
                            answer: item.answer,
                        }
                    },
                )

                return {
                    id: category.id,
                    name: category.attributes.name,
                    questions,
                }
            })

            return this.faq
        },

        /**
         * Fetches all blog entries.
         * @param {{ categorySlug: String }} args
         * @returns { Promise<Array<Object>> }
         */
        fetchBlogentries(args) {
            /**
             * Highlight some articles for some UI magic.
             * Note: This is only a UI flag and does not add or remove
             * any functionality.
             * @param { Array<Object> } blogentries
             * @returns
             */
            const _highlightSomeBlogentries = (blogentries) => {
                // TODO: this should be inside a config or env file
                const entryIndicesToHighlight = [6, 10, 13]

                blogentries.forEach((entry, index) => {
                    if (entryIndicesToHighlight.includes(index)) {
                        entry.highlighted = true
                    }
                })

                return blogentries
            } // eo _highlightSomeBlogentries()

            /**
             * Reduce the blog entry's content to 2-4 lines, based if this
             * article is highlighted or not. Highlighted articles have more
             * space and need approx 280 chars, non-highlighted articles have
             * approx 140 chars.
             * TODO: This logic should be elsewhere because a) pinia is the wrong
             * place for UI magic and b) there are edge-cases in which this does not
             * work properly.
             * @param { Array<Object> } blogentries
             * @returns
             */
            const _reduceBlogentriesContent = (blogentries) => {
                const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g

                blogentries.forEach((entry, index) => {
                    const rawContent = entry.content.replace(htmlRegexG, '')

                    // special case: you are the first entry or a highlighted entry
                    // you have more space to show a preview text when you are the first one
                    // or highlighted
                    if (entry.hightlighted === true || index === 0) {
                        entry.content_preview = `<p>${rawContent.slice(
                            0,
                            280,
                        )}...</p>`
                        return // continue
                    }

                    // "normal" case
                    entry.content_preview = `<p>${rawContent.slice(
                        0,
                        140,
                    )}...</p>`
                }) // eo _reduceBlogentriesContent()

                return blogentries
            } // eo _reduceBlogentriesContent()

            const _optimizeImages = (blogentries) => {
                const _getOptimizedImage = (photoObject) => {
                    // Structure should look like: phote: { ..., formats: { large: { url: ... }, medium: { url: ... }, small: { url: ... } }}
                    // Use the medium format for the team photos
                    // Otherwise fall back to large or the original
                    if (photoObject) {
                        if ('formats' in photoObject) {
                            if ('medium' in photoObject.formats) {
                                return photoObject.formats.medium
                            }
                            if ('large' in photoObject.formats) {
                                return photoObject.formats.large
                            }
                        }

                        return photoObject
                    }

                    return undefined
                } // eo _getOptimizedImage()

                blogentries.forEach((blogentry) => {
                    blogentry.image_preview_optimized = _getOptimizedImage(
                        blogentry.image_preview.data.attributes,
                    )
                })

                return blogentries
            } // eo _optimizeImages()

            const config = useRuntimeConfig()
            const baseUrl = config.public.sources.strapi.url
            let urlQuery = '?populate=*&sort=createdAt:DESC'

            if (args && 'categorySlug' in args) {
                urlQuery += `&filters[blogcategories][slug][$eq]=${args.categorySlug}`
            }

            // Stop here if we already have some articles in our state
            // If there are already articles we indicate that these are all currenty articles,
            // so we do not have to load them again.
            if (this.blogentries.length > 0) {
                if (args && 'categorySlug' in args) {
                    return this.blogentries.filter(
                        (be) =>
                            be.blogcategories.data.find(
                                (bc) =>
                                    bc.attributes.slug === args.categorySlug,
                            ) !== undefined,
                    )
                }

                return this.blogentries
            }

            return $fetch(`${baseUrl}/api/blogentries${urlQuery}`)
                .then((response) => response.data)
                .then((blogentries) => {
                    blogentries = blogentries.map(
                        (blogentry) => blogentry.attributes,
                    )
                    // Prepare some UI magic (highlight some articles)
                    blogentries = _highlightSomeBlogentries(blogentries)
                    // Reduce the content so we can have a better UI in the preview boxes
                    blogentries = _reduceBlogentriesContent(blogentries)
                    // Sanitize the blogentries content with absolute paths (this is mainly a strpi thing, but currently no solution for strapi)
                    // Since we upload our images to AWS we do not need to sanitize
                    // blogentries = _sanitizeBlogentriesContent(blogentries);
                    // Optimize images of blogentries
                    blogentries = _optimizeImages(blogentries)

                    // Save the blogentries for later
                    this.blogentries = blogentries

                    return this.blogentries
                })
                .catch((err) => {
                    // TODO: show error to user (as toast?)
                    Bugsnag.notify(err, (event) => {
                        event.context = 'strapi-store.fetch-blogentries'
                    })

                    return []
                })
        }, // eo fetchBlogentries()

        /**
         * Fetches one specific blog entry.
         * @param {{ slug: String }}
         * @returns { Promise<Array<Object>> }
         */
        fetchBlogentry(args) {
            const config = useRuntimeConfig()
            const baseUrl = config.public.sources.strapi.url

            // Stop here if we already have this articles in our state
            if (this.blogentries.length > 0) {
                if (args && 'slug' in args) {
                    return this.blogentries.find((b) => b.slug === args.slug)
                }
            }

            return $fetch(
                baseUrl +
                    `/api/blogentries?filters[slug][$eq]=${args.slug}&populate=*`,
            )
                .then((response) => response.data)
                .then((blogentries) => {
                    blogentries.map((blogentry) => blogentry.attributes)

                    if (blogentries.length > 0) {
                        return blogentries[0]
                    } else {
                        return undefined
                    }
                })
                .catch((err) => {
                    // TODO: show error to user (as toast?)
                    Bugsnag.notify(err, (event) => {
                        event.context = 'strapi-store.fetch-blogentry'
                    })

                    return undefined
                })
        }, // eo fetchBlogentry()

        /**
         * Fetches all blog categories.
         * @returns { Promise<Array<Object>> }
         */
        fetchBlogcategories() {
            const config = useRuntimeConfig()
            const baseUrl = config.public.sources.strapi.url

            // Stop here if we saved some articles before
            if (this.blogcategories.length > 0) {
                return this.blogcategories
            }

            return $fetch(baseUrl + `/api/blogcategories`)
                .then((response) => response.data)
                .then((blogcategories) => {
                    this.blogcategories = blogcategories.map(
                        (blogcategory) => blogcategory.attributes,
                    )
                    this.blogcategories.unshift({ name: 'Alle', slug: null })

                    return this.blogcategories
                })
                .catch((err) => {
                    // TODO: show error to user (as toast?)
                    Bugsnag.notify(err, (event) => {
                        event.context = 'strapi-store.fetch-blogcategories'
                    })

                    return undefined
                })
        }, // eo fetchBlogcategories()
    },
})
